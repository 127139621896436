<template>
	<div class="fixed top-0 left-0 w-full h-full z-30 overflow-y-auto">
		<div class="flex w-full justify-end">
			<div
				class="md:flex w-full shadow h-full px-3 xl:px-6 py-3 xl:py-10 sm:ml-0 min-h-screen"
				style="background-color: rgb(251 251 251)">
				<component :is="components[modalComponent]" :name="modalComponent" :props="modalProps" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const ManageCourseElementModal = defineAsyncComponent(() =>
	import('./Modals/ManageCourseElementModal.vue')
)
const ManageCourseDetailsModal = defineAsyncComponent(() =>
	import('./Modals/ManageCourseDetailsModal.vue')
)
const ManageFormElementModal = defineAsyncComponent(() =>
	import('./Modals/ManageFormElementModal.vue')
)
const ManageAssessmentElementModal = defineAsyncComponent(() =>
	import('./Modals/ManageAssessmentElementModal.vue')
)
const ManageRecordingDetailsModal = defineAsyncComponent(() =>
	import('./Modals/ManageRecordingDetailsModal.vue')
)
const ManageRecordingModal = defineAsyncComponent(() => import('./Modals/ManageRecordingModal.vue'))

const components = {
	ManageCourseElementModal,
	ManageCourseDetailsModal,
	ManageFormElementModal,
	ManageAssessmentElementModal,
	ManageRecordingDetailsModal,
	ManageRecordingModal
}

const modalComponent = computed(() => store.getters.getModalComponent)
const modalProps = computed(() => store.getters.getModalProps)
</script>
<style scoped>
.modal-header {
	display: flex;
	width: 100%;
	height: fit-content;
	justify-content: space-between;
	align-items: center;
}

.modal-title {
	font-size: 24px;
	line-height: 150%;
}

.modal-action-bar {
	display: flex;
	padding: 1em 0;
	gap: 1em;
	justify-content: flex-end;
	align-items: center;
}
</style>
